import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/providers/api.service";
import { AuthService } from "src/app/providers/auth.service";
import { CommonService } from "src/app/providers/common.service";
import { HelperService } from "src/app/providers/helper.service";

@Component({
  selector: "app-login",
  templateUrl: "login.component.html"
})
export class LoginComponent implements OnInit {
  focus;
  focus1;
  model: any = {};
  loadingIndicator: boolean;
  email: any;
  constructor(public apiService: ApiService, public commonService: CommonService, public authService: AuthService, public helperService: HelperService, private router: Router,) {

  }

  validateForm(field, e) {
    switch (field) {
      case 'email':
        if (!String(this.model.email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )) {
          this.helperService.showNotification('warning', 'Enter Valid Email');
          this.model.email = '';
        }

        break;
    }

  }
  ngOnInit() { }


  OnSubmit() {
    console.log(this.model);

    if (!this.model.email
      || this.model.email == '') {
      this.helperService.showNotification('warning', 'Enter email');
      return 0;
    }

    this.loadingIndicator = true;
    // if(this.signInForm.value.email !='' && this.signInForm.value.password !=''){
    this.apiService.callapi(this.apiService.APIS.AUTH_LOGIN, this.model).subscribe(data => {

      if (data && data['token']) {
        this.commonService.isloggedin = true;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('token', data['token']);

        localStorage.setItem('currentUser', JSON.stringify(data));
        // this.authService.currentUserSubject.next(data['user']);
        this.helperService.showNotification('info', 'Welcome             ');
        if (data.user_type == 'super_admin') {
          this.router.navigate(['/admin/dashboard']);
        } else {
          this.router.navigate(['/']);
        }

      }
    }, err => {
      this.loadingIndicator = false;
      //this.commonService.notify('error', err.error);
      this.helperService.showNotification('warning', err.error);

      console.log(err);
    });
  }
  sumsg(arg0: string) {
    throw new Error("");
  }
}
