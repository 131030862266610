import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


import { HelperService } from './helper.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    apiUrl: string = environment.apiUrl;

    canSyncDBCache = true;
    flag_refreshlist = false;
    tempdata: any = {};
    isLoggedIn: boolean = false;


    metadata: any = {
        bloodgroups: [{ name: 'A+' }, { name: 'B+' }, { name: 'AB+' }, { name: 'O+' }, { name: 'A-' }, { name: 'B-' }, { name: 'AB-' }, { name: 'O-' }],
        maritalstatuses: [{ name: 'Married' }, { name: 'Single' }, { name: 'Divorced' }, { name: 'Other' }],
        genders: [{ name: 'Male' }, { name: 'Female' }, { name: 'Other' }]
    };

    DBCache: any = {};
    syncCaches = [
        { cache_key: 'DEPARTMENTS' }, { cache_key: 'BRANCHES' }
    ];
    //API Definitions
    APIS: any = {
        AUTH_LOGIN: { endpoint: 'auth/login', method: 'post' },

        FILE_UPLOAD: { endpoint: 'file/upload', method: 'post' },

        CAMPAIGN_BYID: { endpoint: 'campaign/byId', method: 'get' },
        CAMPAIGN_LIST: { endpoint: 'campaign/list', method: 'get' },
        CAMPAIGN_ADLIST: { endpoint: 'campaign/adlist', method: 'get' },
        CAMPAIGN_ADDEDIT: { endpoint: 'campaign/addedit', method: 'post' },
        DELETECAMPAIGN: { endpoint: 'campaign/delete', method: 'post' },


        ROLE_LIST: { endpoint: 'role/list', method: 'get' },


        USER_LIST: { endpoint: 'user/list', method: 'get' },
        USER_ADDEDIT: { endpoint: 'user/addedit', method: 'post' },
        USER_PROFILE: { endpoint: 'user/fullprofile', method: 'get' },
        USER_CONTACTUS: { endpoint: 'user/contactUs', method: 'post'},

        MASTER_LIST: { endpoint: 'master/list', method: 'get' },
        MASTER_ADLIST: { endpoint: 'master/adlist', method: 'get' },
        MASTER_ADDEDIT: { endpoint: 'master/addedit', method: 'post' },

        ASSETSCLASS_LIST: { endpoint: 'assetsClass/list', method: 'get' },
        ASSETSCLASS_ADDEDIT: { endpoint: 'assetsClass/addedit', method: 'post' },
        DELETEASSETSCLASS: { endpoint: 'assetsClass/delete', method: 'post' },

        ENQUIRY_LIST: { endpoint: 'enquiry/list', method: 'get' },
        ENQUIRY_ADDEDIT: { endpoint: 'enquiry/addedit', method: 'post' },

        DELETEROLE: { endpoint: 'role/roledelete', method: 'post' }

    }

    constructor(private http: HttpClient, public helperService: HelperService //public events: Events,
    ) { }

    callapi(api: any, params = null, CACHE_KEY = null) {
        switch (api.method) {
            case 'get':

                return this.http.get<any>(this.apiUrl + '/' + api.endpoint, { params: params }).pipe(
                    map(data => {
                        //this.helperService.storage_set('cache:'+api.endpoint,data);
                        //this.upsertDBcache(CACHE_KEY, data?.data ? data.data : data);
                        return data;
                    }));
                break;

            case 'post':
                return this.http.post<any>(this.apiUrl + '/' + api.endpoint, params).pipe(
                    map(data => {
                        return data;
                    }));
                break;
        }
    }

    // upsertDBcache(CACHE_KEY = null, data: any) {

    //     if (CACHE_KEY) {
    //         console.log('Upsert DB Cache : ' + CACHE_KEY);
    //         this.helperService.storage_set('DBCache:' + CACHE_KEY, data).then(() => {
    //             this.DBCache[CACHE_KEY] = data.data;
    //         });
    //     }
    // }

    // refreshDBcache() {
    //     console.log('Refreshing DB Cache');
    //     this.syncCaches.forEach(current => {
    //         this.helperService.storage_get('DBCache:' + current.cache_key).then(data => {
    //             this.DBCache[current.cache_key] = data.data;
    //         });
    //     });
    // }

    syncDBCache() {
        this.canSyncDBCache = false;
        console.log('Syncing DB Cache');
        this.syncCaches.forEach(current => {
            // this.callapi(this.PAGES[current.page_key].apis.list, {}, current.page_key).subscribe();
        });
    }


    uploadFile(file, reportProgress = false) {
        console.log(file);
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return this.http.post<any>(this.apiUrl + '/' + this.APIS.FILE_UPLOAD.endpoint, formData, {
            reportProgress: true,
            observe: 'events'
        }).pipe(map(res => {
            console.log("file result", res);
            return res;
        }))
    }

    getObjFromDBCache(CacheName: string, _id: string) {
        let object: any = {};
        // console.log(CacheName,_id)
        for (let obj of this.DBCache[CacheName]) {
            if (obj?._id == _id) {
                object = obj;
                //console.log(JSON.stringify(obj));
                break;
            }
        }
        return object;
    }
}