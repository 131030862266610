import { Component, OnInit, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { AuthService, CommonService } from "src/app/providers";
import { ApiService } from "src/app/providers/api.service";
import { HelperService } from "src/app/providers/helper.service";

var misc: any = {
  sidebar_mini_active: true
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}
//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    type: 'link',
    icontype: 'ni-shop text-primary',
    isCollapsed: true,
  },
  {
    path: "/admin/users",
    title: "Users",
    type: "link",
    icontype: "ni-single-02 text-orange"
  },
  {
    path: "/admin/campaign",
    title: "Campaign",
    type: "link",
    icontype: "ni-bullet-list-67 text-blue"
  },
  {
    path: "/admin/roles",
    title: "Roles",
    type: "link",
    icontype: "ni-collection text-primary"
  },
  // {
  //   path: "/admin/assets-class",
  //   title: "Assets Class",
  //   type: "link",
  //   icontype: "ni-collection text-primary"
  // },
  {
    path: "/admin/master",
    title: "Master",
    type: "link",
    icontype: "ni-collection text-primary"
  },
   {
    path: "/admin/enquiry",
    title: "Enquiry",
    type: "link",
    icontype: "ni-collection text-primary"
  }
];

@Component({
  selector: "app-websidebar",
  templateUrl: "./websidebar.component.html",
  styleUrls: ["./websidebar.component.scss"]
})
export class WebSidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;
  public focus;
  public listTitles: any[];
  public location: Location;
  sidenavOpen: boolean = true;
  currentUser: any;
  test: Date = new Date();
   model: any = {};
  rmodel: any = {};
   formModal: BsModalRef;
  form = {
    keyboard: true,
    class: "modal-dialog-centered modal-md",

  };
   rform = {
    keyboard: true,
    class: "modal-dialog-centered modal-md",

  };
  roles: any;
  is_signinform:any = false;
  sub_assets: any=[];
  loadingIndicator: boolean;
  tabro: any = [];
  acname: any;
  acid: any;
  isloggedIn: boolean = false;
  userToken:any;
  
  constructor(private router: Router,
   
    private authService: AuthService,
    public commonService:CommonService,
    public apiService: ApiService,
    public toastr: ToastrService, 
    public helperService: HelperService,
    private modalService: BsModalService,
    ) {}

  ngOnInit() {

    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe(event => {
      this.isCollapsed = true;
    });

    this.listTitles = ROUTES.filter(listTitle => listTitle);
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.authService.currentUser.subscribe(data => this.currentUser = data);
    console.log(this.currentUser);
    var html = document.getElementsByTagName("html")[0];
    // html.classList.add("auth-layout");
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("bg-default");
    // var navbar = document.getElementsByClassName("navbar-horizontal")[0];
    // navbar.classList.add("navbar-light");
    // navbar.classList.add("navbar-transparent");
   this.getList();
  }

  is_login(){
    if(this.commonService.isloggedin){
      return false;
    }else{
      return true;
    }
  }
  islogin(acname, acid, sacid, modalForm: TemplateRef<any> ) {
    this.acname = acname;
    this.acid = acid;
    localStorage.removeItem('sub_asset_class_Id');
    localStorage.removeItem('asset_class_Id');
    console.log(acid, sacid, "campain");
    if(acid !='all'){
    localStorage.setItem('asset_class_Id', acid);
    localStorage.setItem('sub_asset_class_Id', sacid);
    }
    console.log(this.currentUser);
    //this.currentUser = this.authService.getCurrentUser();
    if (this.currentUser) {
      this.router.navigate(['/campaign', acname, acid]);

    } else {
      this.formModal = this.modalService.show(modalForm, this.form);
    }
  }
  logout() {
    this.authService.logout();
    this.router.navigate(['/']);
    this.commonService.isloggedin = false;
    this.isloggedIn = false;
  }
  getList() {
    this.apiService.callapi(this.apiService.APIS.MASTER_LIST, {}).subscribe(res => {
      this.tabro = res.data.filter(a => a.image_url != null && a.type == 'assets_class');
      console.log(this.tabro, "data");
       this.sub_assets = res.data.filter(a => a.type == 'sub_assets_class');
      // console.log('role list find ', this.tabrows);
      // console.log('role list find ', this.sub_assets_class);
      // this.loading = false;
    });
  }

  ngOnDestroy() {
    var html = document.getElementsByTagName("html")[0];
    // html.classList.remove("auth-layout");
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("bg-default");
    // var navbar = document.getElementsByClassName("navbar-horizontal")[0];
    // navbar.classList.remove("navbar-light");
    // navbar.classList.remove("navbar-transparent");
  }
  openFormModal(modalForm:TemplateRef<any>){
  //this.hide();
  this.formModal = this.modalService.show(modalForm, this.rform);
  }
  hide() {
    this.formModal.hide();
  }
  userRegister() {
    this.apiService.callapi(this.apiService.APIS.USER_ADDEDIT, this.rmodel).subscribe(res => {
      console.log(res);
      this.hide();
      this.helperService.showNotification('info', 'Register Successfully, Login To Continue');
    }, err => {
      console.log(err);
      if (err.error.codeName == 'DuplicateKey') {
        this.helperService.showNotification('warning', 'User with Email Or Mobile Already Exist');
      }
    });
  }
  OnSubmit() {
    this.loadingIndicator = true;
    // if(this.signInForm.value.email !='' && this.signInForm.value.password !=''){
    this.apiService.callapi(this.apiService.APIS.AUTH_LOGIN, this.model).subscribe(data => {
      this.hide();
      if (data && data['token']) {
        this.commonService.isloggedin = true;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('token', data['token']);

        localStorage.setItem('currentUser', JSON.stringify(data));
        this.authService.currentUserSubject.next(data);
        // this.authService.currentUserSubject.next(data['user']);
        this.helperService.showNotification('info', "Login Successfully");
        // if (data.user_type == 'super_admin') {
        //   this.router.navigate(['/admin/dashboard']);
        // } else {
          this.router.navigate(['/campaign', this.acname, this.acid]);
          //this.router.navigate(['/web/campaign', 'all', 'all']);
        // }

      }
    }, err => {
      this.loadingIndicator = false;
      this.commonService.notify('error', err.error);
      this.helperService.showNotification('warning', err.error);
      console.log(err);
    });
  }
  onMouseEnterSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  }
  onMouseLeaveSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  }
  minimizeSidebar() {
    const sidenavToggler = document.getElementsByClassName(
      "sidenav-toggler"
    )[0];
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("g-sidenav-pinned")) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove("g-sidenav-pinned");
      body.classList.add("g-sidenav-hidden");
      sidenavToggler.classList.remove("active");
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add("g-sidenav-pinned");
      body.classList.remove("g-sidenav-hidden");
      sidenavToggler.classList.add("active");
      misc.sidebar_mini_active = true;
    }
  }
  islogins( modalForm: TemplateRef<any> ) {
    // this.id = id
    this.userToken= localStorage.getItem('token');
    if(this.userToken == null || undefined) {
      this.formModal = this.modalService.show(modalForm, this.form);
      // this.isloggedIn = true
    } else {
      // this.router.navigate(['/campaign-profile', id])
    }    
  }

  tcondition(){
    this.router.navigate(['/terms-conditions']);
    this.hide();
  }
  
}
