// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // apiUrl: 'http://localhost:3000',
  // RapiUrl: 'http://localhost:3000',

  //apiUrl: 'https://assetspositive-portal-api.mobify.bz',
  //RapiUrl: 'https://assetspositive-portal-api.mobify.bz',

  //  apiUrl: 'http://206.189.143.142:3000',
  // RapiUrl: 'http://206.189.143.142:3000',

   //apiUrl: 'http://15.206.203.9:3000',
   //RapiUrl: 'http://15.206.203.9:3000',

   apiUrl: 'https://api.assetspositive.com',
   RapiUrl: 'https://api.assetspositive.com'

   

  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
