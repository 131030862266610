import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { ApiService } from 'src/app/providers/api.service';
import { HelperService } from 'src/app/providers/helper.service'
@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit {
  assets: any;
  searchqlimit: any;
  searchq: any;
  activeAssetsClass: any;
  assets_class: any=[];
  sub_assets_class: any=[];
  isFoundersForm: boolean = false;
  foundersArr: any = [];
  deleteId: any;
  delRolecat: any;
  constructor(private modalService: BsModalService, public toastr: ToastrService,
    public apiService: ApiService, public helperService: HelperService) { }

  formModal: BsModalRef;
  deleteModal: BsModalRef;
  deleteCampaign:BsModalRef;
  form = {
    ignoreBackdropClick: true,
    keyboard: true,
    class: 'modal-dialog-centered modal-lg',
  };
  page = {
    limit: 10,
    count: 0,
    offset: 0,
    orderBy: 'id',
    orderDir: 'desc'
  };
  tabrows: any = [];
  model: any = {};
  founderModel: any = {};
  companyModel: any = {};
  adwidth: number = 1400;
  adheight: number = 1400;
  showprogressbar = false;
  imageChangedEvent: any = '';
  videoChangedEvent: any = '';
  filedata: any;
  progressbarwidth: Number = 0;
  imgflag: any;
  isFounderedit = false;
  founderActionindex: any;

  ngOnInit(): void {
    this.getCampaign();
    this.getssetsclass();
    this.getActiveAssetsClass();
  }

  getActiveAssetsClass() {
    this.apiService.callapi(this.apiService.APIS.MASTER_LIST).subscribe(res=>{
      console.log(res);
      this.activeAssetsClass = res.data;
      this.assets_class = res.data.filter(a=>a.type == 'assets_class');
      console.log(this.assets_class);
      this.sub_assets_class = res.data.filter(a=>a.type == 'sub_assets_class');
    })
  }

  getSubAsset(id){
   // console.log(id);
    if(id){
      return this.sub_assets_class.filter(a => a.asset_class == id);

    }
  }

  deleteFounder(i){
    this.foundersArr.splice(i, 1);
    this.helperService.showNotification('success', 'Founder Deleted, kindly save');
  }

  addFounder(){
    if(this.isFounderedit) {
      this.foundersArr[this.founderActionindex] = this.founderModel;
      this.isFounderedit = false;
      this.founderActionindex = null;
    }else{
      let tempfounder = {
        "name": this.founderModel.name,
        "linked_url": this.founderModel.linkedin_url,
        "founder_url" : this.founderModel.founder_url
      }
  
      this.foundersArr.push(tempfounder);
    }
    

    this.founderModel = {};
    this.isFoundersForm = false;
  }

  getCampaign() {
    if (this.searchqlimit) {
      this.page.limit = this.searchqlimit;
    } else {
      this.page.limit = 10;
    }
    let params: any = {};
    params.limit = this.page.limit;
    params.pageoffset = this.page.offset;
    let temp = 0;
    this.searchq ? params.searchq = this.searchq : temp = 1;

    this.apiService.callapi(this.apiService.APIS.CAMPAIGN_ADLIST, params).subscribe(data => {
      console.log(data, "data");
      this.page.count = data['count'];
      this.tabrows = data['rows'];
      console.log(this.page.count, this.tabrows, "this.tabrows");
    })
  }


  pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.page.offset = pageInfo.offset;
    this.getCampaign();
  }
  onChange(event) {
    this.pageCallback({ offset: 0 });
  }

  getssetsclass() {
    this.apiService.callapi(this.apiService.APIS.ASSETSCLASS_LIST).subscribe(data => {

      this.assets = data['data'];
      console.log(this.assets, "this.tabrows");
    })
  }

  campaignEdit() {
    console.log(this.model);
    this.model.founders = this.foundersArr;
    this.model.company  = this.companyModel;
    this.apiService.callapi(this.apiService.APIS.CAMPAIGN_ADDEDIT, this.model).subscribe(data => {
      this.getCampaign();
      this.modalService.hide(1);
      this.helperService.showNotification('success', 'Changes Updated Successfully');
      this.modalService.hide(1);
    })
  }

  editcampaign(row) {
    this.model = this.helperService.cloneWR(row);
    this.model.asset_class = this.model.asset_class._id;
    this.model.sub_asset_class = this.model.sub_asset_class._id;
    this.foundersArr = this.model.founders? this.model.founders:[];
    this.companyModel = this.helperService.cloneWR(row.company);
  }

  uploadFile(event: any) {
    this.showprogressbar = true;
    const file = event.target.files[0];
   const image = new Image();
   image.src = window.URL.createObjectURL(file);
    image.onload = () =>{
      if (image.height !== 512 && image.width !== 228 && this.imgflag == 'banner') {
        this.helperService.showNotification('info', 'Size should be 512x228');
        return 0;
      }
    }

    //image.onload = () => {
     // console.log(image.height, image.width);
      console.log(this.adheight, this.adwidth);
      //if (image.height === this.adheight && image.width === this.adwidth) {
        console.log("start");
        this.videoChangedEvent = event;
        this.apiService.uploadFile(file).subscribe(data => {
          this.filedata = data;
          console.log("file data", this.filedata);
          if (this.filedata.type == 1) {
            // console.log("upload file per",this.filedata.loaded);
            // this._common.notify('info','Please wait File is uploading');
            this.progressbarwidth = (this.filedata.loaded / this.filedata.pull) * 100;
          }
          if (this.filedata.type == 4) {
            // this.showprogressbar =false;
            this.progressbarwidth = 0;
            this.helperService.showNotification('info', 'File Uploaded Successfully');
            this.showprogressbar = false;
          
              if (this.imgflag == 'image') {
                this.model.logo_url = this.filedata['body']['data'].url;
                console.log("upload file path", this.model.logo_url);
              } else if(this.imgflag == 'banner') {
                this.model.banner_url = this.filedata['body']['data'].url;
                console.log("upload file path", this.model.banner_url);
              }else if(this.imgflag == 'deck_url') {
                this.model.deck_url = this.filedata['body']['data'].url;
                console.log("upload file path", this.model.deck_url);
              } else if(this.imgflag == 'analysis_url') {
                this.model.analysis_url = this.filedata['body']['data'].url;
                console.log("upload file path", this.model.analysis_url);
              } else if(this.imgflag == 'finacial_projection_url') {
                this.model.finacial_projection_url = this.filedata['body']['data'].url;
                console.log("upload file path", this.model.finacial_projection_url);
              } else if(this.imgflag == 'founder') {
                this.founderModel.founder_url = this.filedata['body']['data'].url;
                console.log("upload file path", this.founderModel.founder_url);
              } 
              
              
          
          }
        })
        console.log("file", this.videoChangedEvent);
      // } else {
      //   event.target.value = '';
      //   this.helperService.showNotification('warning', 'File Size (height, width) Should be ' + this.adwidth + 'x ' + this.adheight);
      // }
   // };
  }

  uploadVideo(event: any) {
    this.showprogressbar = true;
    const file = event.target.files[0];


    console.log("start");
    this.videoChangedEvent = event;
    this.apiService.uploadFile(file).subscribe(data => {
      this.filedata = data;
      console.log("file data", this.filedata);
      if (this.filedata.type == 1) {
        // console.log("upload file per",this.filedata.loaded);
        // this._common.notify('info','Please wait File is uploading');
        this.progressbarwidth = (this.filedata.loaded / this.filedata.total) * 100;
      }
      if (this.filedata.type == 4) {
        // this.showprogressbar =false;
        this.progressbarwidth = 0;
        this.helperService.showNotification('info', 'File Uploaded Successfully');
        this.showprogressbar = false;
        this.model.video_url = this.filedata['body']['data'].url;
        console.log("upload file path", this.model.video_url);
      }
    })
    console.log("file", this.videoChangedEvent);
  }




  openFormModal(modalForm: TemplateRef<any>) {
    this.formModal = this.modalService.show(modalForm, this.form);
  }


  openDeleteFormModal(modalForm: TemplateRef<any>) {
    this.deleteModal = this.modalService.show(modalForm, this.form);
  }



  deleteImage() {

    if (this.imgflag == 'image') {
      this.model.logo_url = null;
    }
    else if (this.imgflag == 'banner') {
      this.model.banner_url = null;
    }
    else if (this.imgflag == 'deck_url') {
     this.model.deck_url = null;
    } 
    else if (this.imgflag == 'analysis_url') {
      this.model.analysis_url = null;
    } 
    else if (this.imgflag == 'finacial_projection_url') {
      this.model.finacial_projection_url = null;
    } 
    else if (this.imgflag == 'founder') {
      this.founderModel.founder_url = null;
    }
    else{
      this.model.video_url = null;

    }
    this.deleteModal.hide();
    this.helperService.showNotification('info', this.imgflag + 'is removed, save changes to update');
}

  hide() {
    this.formModal.hide();
  }
  hidecam() {
    this.deleteCampaign.hide();
  }

  openDeleteModal(modalForm: TemplateRef<any>) {
    this.deleteCampaign = this.modalService.show(modalForm, this.form)
  }

  
  deleteid(id,flag){
    console.log("delete id",id + ' ' + flag);
    this.deleteId=id;
    this.delRolecat=flag
  }
  deleteCam(){
    this.apiService.callapi(this.apiService.APIS.CAMPAIGN_ADDEDIT, {_id: this.deleteId, is_deleted:true}).subscribe(res=>{
      console.log(res);
      this.helperService.showNotification('info', 'Deleted Successfully');
      this.getCampaign(); 
      this.hidecam();
    },
    err=>{
      console.log(err);
      if(err.statusText=='OK'){
        this.getCampaign()          
        this.helperService.showNotification('warning', err.error);
        ;
      }
    });

  }

}
