import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class HelperService {
    apiUrl: string = environment.apiUrl;
    //private _storage: Storage | null = null;
    liveparams: any = {};
    data_appversion: any = {};
    constructor(private http: HttpClient, private location: Location, public toastr: ToastrService) {
        this.initStorage();
    }

    initStorage() {
        console.log('storage');
        //this.storage.create();
    }


    public storage_set(settingName, value) {
        //return this.storage.set(`setting:${settingName}`, value);
    }
    public async storage_get(settingName) {
        //return await this.storage.get(`setting:${settingName}`);
    }
    public async storage_remove(settingName) {
        //return await this.storage.remove(`setting:${settingName}`);
    }
    public storage_clear() {
        // this.storage.clear().then(() => {
        //     console.log('all keys cleared');
        // });
    }


    canLoadTutorial() {
        // return this.storage.get('ion_did_tutorial').then(res => {
        //     if (res) {
        //         return false;
        //     } else {
        //         return true;
        //     }
        // });
    }

    getPlainNameString(s) {
        return s.toLowerCase().replace(/\s/g, '');
    }

    cloneWR(p) {
        return JSON.parse(JSON.stringify(p));
    }

    async presentAlert(header, msg = null) {
        // const alert = await this.alertCtrl.create({
        //     cssClass: 'my-custom-class',
        //     header: header,
        //     subHeader: msg,
        //     buttons: ['OK']
        // });

        // await alert.present();
    }
    async presentToast(type, header, msg = null) {
        let color = 'primary';
        console.log(type);
        switch (type) {

            case 'success':
                color = "success";
                break;

            case 'error':
                color = 'danger';
                break;

            default:
                color = 'primary';
        }
        // const toast = await this.toastCtrl.create({
        //     header: header,
        //     message: msg,
        //     duration: 3000,
        //     color: color,
        //     position: 'top',
        //     buttons: [
        //         {
        //             text: 'Done',
        //             role: 'cancel',
        //             handler: () => {
        //                 console.log('Cancel clicked');
        //             }
        //         }
        //     ]
        // });
        //await toast.present();
    }


    fetchLocal(url) {
        return new Promise(function (resolve, reject) {
            var xhr = new XMLHttpRequest
            xhr.onload = function () {
                resolve(new Response(xhr.response, { status: xhr.status }))
            }
            xhr.onerror = function () {
                reject(new TypeError('Local request failed'))
            }
            xhr.open('GET', url)
            xhr.responseType = "blob";
            xhr.send(null)
        })
    }

    dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        var ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], { type: mimeString });
        return blob;

    }
    getNamesFromArray(data) {
        let a = [];
        if (!Array.isArray(data)) {
            a = [data];
        } else { a = data; }
        let mapStringName = a.map(item => item.name);
        return mapStringName;
    }

    goBack() {
        this.location.back();
    }

    setAppVersionDetails(data: any) {
        this.data_appversion = data;

    }

    getAppVersionDetails() {
        return this.data_appversion;
    }

    download(url: string): Observable<Blob> {
        return this.http.get(url, {
            responseType: 'blob'
        })
    }
    createDateFormat(dateform) {
        if (dateform) {
            // console.log("date seldcted",dateform);
            // console.log("final date",dateform.year +'-'+ dateform.month+'-'+dateform.day);
            return dateform.year + '-' + dateform.month + '-' + dateform.day;
            //  return dateform;
        } else {
            return false;
        }
    }
    showNotification(type, message) {
        const color = Math.floor(Math.random() * 5 + 1);
        if (type === "default") {
            this.toastr.show(
                message,
                "",
                {
                    timeOut: 8000,
                    closeButton: false,
                    enableHtml: true,
                    tapToDismiss: false,
                    titleClass: "alert-title",
                    positionClass: "toast-bottom-right",
                    toastClass:
                        "ngx-toastr alert alert-dismissible alert-default alert-notify"
                }
            );
        }
        if (type === "danger") {
            this.toastr.show(
                message,
                "",
                {
                    timeOut: 8000,
                    closeButton: false,
                    enableHtml: true,
                    tapToDismiss: false,
                    titleClass: "alert-title",
                    positionClass: "toast-bottom-right",
                    toastClass:
                        "ngx-toastr alert alert-dismissible alert-danger alert-notify"
                }
            );
        }
        if (type === "success") {
            this.toastr.show(
                message,
                "",
                {
                    timeOut: 8000,
                    closeButton: false,
                    enableHtml: true,
                    tapToDismiss: false,
                    titleClass: "alert-title",
                    positionClass: "toast-bottom-right",
                    toastClass:
                        "ngx-toastr alert alert-dismissible alert-success alert-notify"
                }
            );
        }
        if (type === "warning") {
            this.toastr.show(
                message,
                "",
                {
                    timeOut: 8000,
                    closeButton: false,
                    enableHtml: true,
                    tapToDismiss: false,
                    titleClass: "alert-title",
                    positionClass: "toast-bottom-right",
                    toastClass:
                        "ngx-toastr alert alert-dismissible alert-warning alert-notify"
                }
            );
        }
        if (type === "info") {
            this.toastr.show(
                message,
                "",
                {
                    timeOut: 8000,
                    closeButton: false,
                    enableHtml: true,
                    tapToDismiss: false,
                    titleClass: "alert-title",
                    positionClass: "toast-bottom-right",
                    toastClass:
                        "ngx-toastr alert alert-dismissible alert-info alert-notify"
                }
            );
        }
    }

}