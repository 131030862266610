import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { PresentationComponent } from "./pages/presentation/presentation.component";
import { AuthGuard } from "./providers/auth.guard";
import { WebLayoutComponent } from "./layouts/web-layout/web-layout.component";

const routes: Routes = [
  {
    path: "presentation",
    component: PresentationComponent
  },
  {
    path: "admin",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      // {
      //   path: "dashboards",
      //   loadChildren: "./pages/dashboards/dashboards.module#DashboardsModule"
      // },
      {
        path: 'dashboard',
        loadChildren: './pages/dashboards/dashboards.module#DashboardsModule',
      },
      {
        path: "components",
        loadChildren: "./pages/components/components.module#ComponentsModule"
      },
      {
        path: "forms",
        loadChildren: "./pages/forms/forms.module#FormsModules"
      },
      {
        path: "tables",
        loadChildren: "./pages/tables/tables.module#TablesModule"
      },
      {
        path: "maps",
        loadChildren: "./pages/maps/maps.module#MapsModule"
      },
      {
        path: "widgets",
        loadChildren: "./pages/widgets/widgets.module#WidgetsModule"
      },
      {
        path: "charts",
        loadChildren: "./pages/charts/charts.module#ChartsModule"
      },
      {
        path: "calendar",
        loadChildren: "./pages/calendar/calendar.module#CalendarModule"
      },
      {
        path: "examples",
        loadChildren: "./pages/examples/examples.module#ExamplesModule"
      },
      {
        path: "users",
        loadChildren: "./pages/users/users.module#UsersModule"
      },
      {
        path: "campaign",
        loadChildren: "./pages/campaign/campaign.module#CampaignModule"
      },
      {
        path: "user-profile",
        loadChildren: "./pages/user-profile/user-profile.module#UserProfileModule"
      },
      {
        path: "roles",
        loadChildren: "./pages/roles/roles.module#RolesModule"
      },
      {
        path: "assets-class",
        loadChildren: "./pages/assetsclass/assetsclass.module#AssetsclassModule"
      },
      {
        path: "master",
        loadChildren: "./pages/master/master.module#MasterModule"
      },
      {
        path: "enquiry",
        loadChildren: "./pages/enquiry/enquiry.module#EnquiryModule"
      }
    ]
  },
  // {
  //   path: "",
  //   component: AuthLayoutComponent,
  //   children: [
  //     {
  //       path: "examples",
  //       loadChildren:
  //         "./layouts/auth-layout/auth-layout.module#AuthLayoutModule"
  //     }
  //   ]
  // },
  {
    path: "",
    component: WebLayoutComponent,
    children: [
      {
        path: "",
        loadChildren:
          "./layouts/web-layout/web-layout.module#WebLayoutModule"
      }
    ]
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "auth",
        loadChildren:
          "./layouts/auth-layout/auth-layout.module#AuthLayoutModule"
      }
    ]
  },
 
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
       anchorScrolling: 'enabled',
      scrollOffset: [0, 64],

    })
    
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
