import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable ,  Subject } from 'rxjs';
// import {NotificationsService} from 'angular2-notifications';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
    options: any = {
        position: ['bottom', 'right'],
      };
      position1 = 'bottom';
      position2 = 'right';
      timeOut = 10;
      showProgressBar = true;
      pauseOnHover = true;
      lastOnBottom = true;
      clickToClose = true;
      maxLength = 0;
      maxStack = 8;
      preventDuplicates = false;
      preventLastDuplicates = false;
      theClass: string;
      rtl = false;
      animate = 'fromRight';
      icons: string;
      subType = 'success';
      title: string;
      msg: string;

    constructor(private router: Router,public toastr: ToastrService) {
        // clear alert message on route change

    }


  //   notify(type,title,msg='message') {
  //       console.log('mesage');
  //   this.servicePNotify.remove();
  //   let options: any = {title: title, msg: msg, type:type};
  //   this.options  = {
  //     position : [
  //       ('position1' in options) ? options.position1 : this.position1,
  //       ('position2' in options) ? options.position2 : this.position2
  //     ],
  //     maxStack: ('maxStack' in options) ? options.maxStack : this.maxStack,
  //     // timeOut: options.timeOut ? options.timeOut : this.timeOut,
  //     timeOut: 1000,
  //     showProgressBar: ('showProgressBar' in options) ? options.showProgressBar : this.showProgressBar,
  //     pauseOnHover: ('pauseOnHover' in options) ? options.pauseOnHover : this.pauseOnHover,
  //     lastOnBottom: ('lastOnBottom' in options) ? options.lastOnBottom : this.lastOnBottom,
  //     clickToClose: ('clickToClose' in options) ? options.clickToClose : this.clickToClose,
  //     maxLength: options.maxLength ? options.maxLength : this.maxLength,
  //     preventDuplicates: ('preventDuplicates' in options) ? options.preventDuplicates : this.preventDuplicates,
  //     preventLastDuplicates: ('preventLastDuplicates' in options) ? options.preventLastDuplicates : this.preventLastDuplicates,
  //     theClass: options.theClass ? options.theClass : this.theClass,
  //     rtl: ('rtl' in options) ? options.rtl : this.rtl,
  //     animate: options.animate ? options.animate : this.animate,
  //     icons: options.icons ? options.icons : this.icons
  //   };

  //   switch (options.type) {
  //     case 'success':
  //       this.servicePNotify.success(
  //         options.title ? options.title : this.title,
  //         options.msg ? options.msg : this.msg
  //       );
  //       break;
  //     case 'error':
  //       this.servicePNotify.error(
  //         options.title ? options.title : this.title,
  //         options.msg ? options.msg : this.msg
  //       );
  //       break;
  //     case 'alert':
  //       this.servicePNotify.error(
  //         options.title ? options.title : this.title,
  //         options.msg ? options.msg : this.msg
  //       );
  //       break;
  //     case 'warn':
  //       this.servicePNotify.error(
  //         options.title ? options.title : this.title,
  //         options.msg ? options.msg : this.msg
  //       );
  //       break;
  //     case 'info':
  //       this.servicePNotify.info(
  //         options.title ? options.title : this.title,
  //         options.msg ? options.msg : this.msg
  //       );
  //       break;
  //     case 'create':
  //       this.servicePNotify.create(
  //         options.title ? options.title : this.title,
  //         options.msg ? options.msg : this.msg,
  //         options.type ? options.type : this.subType
  //       );
  //       break;
  //     case 'html':
  //       this.servicePNotify.html(
  //         options.title ? options.title : this.title,
  //         options.msg ? options.msg : this.msg
  //       );
  //       break;
  //     default:
  //       this.servicePNotify.alert(
  //         options.title ? options.title : this.title,
  //         options.msg ? options.msg : this.msg
  //       );
  //       break;
  //   }
  // }
  notify(type, message) {
    const color = Math.floor(Math.random() * 5 + 1);
    if (type === "default") {
        this.toastr.show(
            message,
            "",
            {
                timeOut: 8000,
                closeButton: true,
                enableHtml: true,
                tapToDismiss: false,
                titleClass: "alert-title",
                positionClass: "toast-bottom-right",
                toastClass:
                    "ngx-toastr alert alert-dismissible alert-default alert-notify"
            }
        );
    }
    if (type === "danger") {
        this.toastr.show(
            message,
            "",
            {
                timeOut: 8000,
                closeButton: true,
                enableHtml: true,
                tapToDismiss: false,
                titleClass: "alert-title",
                positionClass: "toast-bottom-right",
                toastClass:
                    "ngx-toastr alert alert-dismissible alert-danger alert-notify"
            }
        );
    }
    if (type === "success") {
        this.toastr.show(
            message,
            "",
            {
                timeOut: 8000,
                closeButton: true,
                enableHtml: true,
                tapToDismiss: false,
                titleClass: "alert-title",
                positionClass: "toast-bottom-right",
                toastClass:
                    "ngx-toastr alert alert-dismissible alert-success alert-notify"
            }
        );
    }
    if (type === "warning") {
        this.toastr.show(
            message,
            "",
            {
                timeOut: 8000,
                closeButton: true,
                enableHtml: true,
                tapToDismiss: false,
                titleClass: "alert-title",
                positionClass: "toast-bottom-right",
                toastClass:
                    "ngx-toastr alert alert-dismissible alert-warning alert-notify"
            }
        );
    }
    if (type === "info") {
        this.toastr.show(
            message,
            "",
            {
                timeOut: 8000,
                closeButton: true,
                enableHtml: true,
                tapToDismiss: false,
                titleClass: "alert-title",
                positionClass: "toast-bottom-right",
                toastClass:
                    "ngx-toastr alert alert-dismissible alert-info alert-notify"
            }
        );
    }
}

}
