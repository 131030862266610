import { Routes } from "@angular/router";
import { CampaignComponent } from "./campaign.component";

export const CampaignRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "",
        component: CampaignComponent
      }
    ]
  },

];
