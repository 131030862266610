import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
    authService: any;
    currentuser: any;

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
           this.currentuser = JSON.parse(localStorage.getItem('currentUser'));
        if (this.currentuser && this.currentuser.user_type == 'super_admin') {
            // logged in so return true
            //check if token is not expired
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
    checkLogin(url: string) {
        if (this.authService.isLoggedIn()) {
            return true;
        } this.authService.redirectUrl = url;

        this.router.navigate(['/auth/login'], { queryParams: { returnUrl: url } });
    }
}
