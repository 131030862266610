import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { RouterModule } from "@angular/router";
import { CampaignComponent } from "./campaign.component";
import { CampaignRoutes } from "./campaign.routing";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { FormsModule } from '@angular/forms';
import { ModalModule } from "ngx-bootstrap/modal";


@NgModule({
  declarations: [CampaignComponent],
  imports: [CommonModule,
            RouterModule, 
            ModalModule.forRoot(),
            TooltipModule.forRoot(), 
            RouterModule.forChild(CampaignRoutes), 
            BsDropdownModule.forRoot(), 
            CollapseModule.forRoot(),
            NgxDatatableModule,
            FormsModule
          ]
})
export class CampaignModule {}

