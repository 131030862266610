import { Component, OnInit, ElementRef, TemplateRef } from "@angular/core";
import { ROUTES } from "../sidebar/sidebar.component";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

import {
  Location,
  LocationStrategy,
  PathLocationStrategy
} from "@angular/common";
import { AuthService, CommonService } from "src/app/providers";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { HelperService } from "src/app/providers/helper.service";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "src/app/providers/api.service";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-webnavbar",
  templateUrl: "./webnavbar.component.html",
  styleUrls: ["./webnavbar.component.scss"]
})

export class WebNavbarComponent implements OnInit {
  public focus;
  public listTitles: any[];
  public location: Location;
  sidenavOpen: boolean = true;
  currentUser: any;
  test: Date = new Date();
  public isCollapsed = true;
   model: any = {};
  rmodel: any = {};
  // public model = {
  //   name_first: '',
  //   name_last: '',
  //   email: '',
  //   // password: '',
  //   user_type: ''
  // };
   formModal: BsModalRef;
  form = {
    keyboard: true,
    class: "modal-dialog-centered modal-md ap_modal mx-auto my-0",

  };
   rform = {
    keyboard: true,
    class: "modal-dialog-centered modal-md",

  };
  roles: any;
  is_signinform:any = false;
  sub_assets: any=[];
  loadingIndicator: boolean;
  tabro: any = [];
  acname: any;
  acid: any;
  mobile: boolean;
  id: any;
  userToken:any;

  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    private authService: AuthService,
    public commonService:CommonService,
    public apiService: ApiService,
    public toastr: ToastrService, 
    public helperService: HelperService,
    private modalService: BsModalService,
  ) {
    this.location = location;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator

      }
      if (event instanceof NavigationEnd) {
        // Hide loading indicator

        if (window.innerWidth < 1200) {
          document.body.classList.remove("g-sidenav-pinned");
          document.body.classList.add("g-sidenav-hidden");
          this.sidenavOpen = false;
        }
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });

  }

  ngOnInit() {
    if (window.screen.width === 360) { // 768px portrait
      this.mobile = true;
    }
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.authService.currentUser.subscribe(data => this.currentUser = data);
    console.log(this.currentUser);
    var html = document.getElementsByTagName("html")[0];
    // html.classList.add("auth-layout");
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("bg-default");
    // var navbar = document.getElementsByClassName("navbar-horizontal")[0];
    // navbar.classList.add("navbar-light");
    // navbar.classList.add("navbar-transparent");
   this.getList();
  }

  is_login(){
    if(this.commonService.isloggedin){
      return false;
    }else{
      return true;
    }
  }
  islogin(acname, acid, sacid, modalForm: TemplateRef<any> ) {
    this.acname = acname;
    this.acid = acid;
    // localStorage.removeItem('sub_asset_class_Id');
    // localStorage.removeItem('asset_class_Id');
    // console.log(acid, sacid, "campain");
    // if(acid !='all'){
    // localStorage.setItem('asset_class_Id', acid);
    // localStorage.setItem('sub_asset_class_Id', sacid);
    // }
    // console.log(this.currentUser);
    // //this.currentUser = this.authService.getCurrentUser();
    // if (this.currentUser) {
      this.router.navigate(['/campaign', acname, acid]);

    // } else {
    //   this.formModal = this.modalService.show(modalForm, this.form);
    // }
  }
  getList() {

   

    this.apiService.callapi(this.apiService.APIS.MASTER_LIST, {}).subscribe(res => {

      this.tabro = res.data.filter(a => a.image_url != null && a.type == 'assets_class');
      console.log(this.tabro, "data");
       this.sub_assets = res.data.filter(a => a.type == 'sub_assets_class');
      // console.log('role list find ', this.tabrows);
      // console.log('role list find ', this.sub_assets_class);
      // this.loading = false;
    });
  }

  ngOnDestroy() {
    var html = document.getElementsByTagName("html")[0];
    // html.classList.remove("auth-layout");
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("bg-default");
    // var navbar = document.getElementsByClassName("navbar-horizontal")[0];
    // navbar.classList.remove("navbar-light");
    // navbar.classList.remove("navbar-transparent");
  }
  openFormModal(modalForm:TemplateRef<any>){
  //this.hide();
  this.formModal = this.modalService.show(modalForm, this.rform);
}

hide() {
  this.formModal.hide();
}

userRegister(f:NgForm) {
  console.log(this.rmodel);

  this.apiService.callapi(this.apiService.APIS.USER_ADDEDIT, this.rmodel).subscribe(res => {
    console.log(res);
    this.hide();
    this.helperService.showNotification('info', 'Register Successfully, Login To Continue');
    f.resetForm();
  }, err => {
    console.log(err);
    if (err.error.codeName == 'DuplicateKey') {
      this.helperService.showNotification('warning', 'User with Email Or Mobile Already Exist');
    }

  });
}

OnSubmit() {
  console.log(this.model);

  this.loadingIndicator = true;
  // if(this.signInForm.value.email !='' && this.signInForm.value.password !=''){
  this.apiService.callapi(this.apiService.APIS.AUTH_LOGIN, this.model).subscribe(data => {
    this.hide();
    if (data && data['token']) {
      this.commonService.isloggedin = true;
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('token', data['token']);

      localStorage.setItem('currentUser', JSON.stringify(data));
      this.authService.currentUserSubject.next(data);
      // this.authService.currentUserSubject.next(data['user']);
      this.helperService.showNotification('info', "Login Successfully");
      // if (data.user_type == 'super_admin') {
      //   this.router.navigate(['/admin/dashboard']);
      // } else {
        this.router.navigate(['/campaign', this.acname, this.acid]);
        //this.router.navigate(['/web/campaign', 'all', 'all']);
      // }

    }
  }, err => {
    this.loadingIndicator = false;
    this.commonService.notify('error', err.error);
    this.helperService.showNotification('warning', err.error);

    console.log(err);
  });
}

islogins( modalForm: TemplateRef<any> ) {
  // this.id = id
  this.userToken= localStorage.getItem('token');
  if(this.userToken == null || undefined) {
    this.formModal = this.modalService.show(modalForm, this.form);
  } else {
    // this.router.navigate(['/campaign-profile', id])
  }    
}
  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Dashboard";
  }

  openSearch() {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  }
  closeSearch() {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  }
  openSidebar() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }
  logout() {
    this.authService.logout();
    this.router.navigate(['/']);
    this.commonService.isloggedin = false;
    this.apiService.isLoggedIn = false;
  }
  toggleSidenav() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }
  tcondition(){
    this.router.navigate(['/terms-conditions']);
    this.hide();
  }
}
