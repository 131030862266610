import { Component, OnInit, OnDestroy,TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "src/app/providers/common.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ApiService } from "src/app/providers/api.service";
import { AuthService } from "src/app/providers/auth.service";
import { HelperService } from "src/app/providers/helper.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-web-layout",
  templateUrl: "./web-layout.component.html",
  styleUrls: ["./web-layout.component.scss"]
})
export class WebLayoutComponent implements OnInit, OnDestroy {
  test: Date = new Date();
  public isCollapsed = true;
  currentUser: any;
   model: any = {};
  rmodel: any = {};
   formModal: BsModalRef;
  form = {
    keyboard: true,
    class: "modal-dialog-centered modal-md",

  };
   rform = {
    keyboard: true,
    class: "modal-dialog-centered modal-md",

  };
  roles: any;
  is_signinform:any = false;
  sub_assets: any=[];
  loadingIndicator: boolean;
  tabro: any = [];
  acname: any;
  acid: any;

  constructor(public apiService: ApiService,public toastr: ToastrService, public authService: AuthService, public helperService: HelperService,private router: Router, public commonService: CommonService,private modalService: BsModalService,) { }

  ngOnInit() {
    this.authService.currentUser.subscribe(data => this.currentUser = data);
    console.log(this.currentUser);
    var html = document.getElementsByTagName("html")[0];
    // html.classList.add("auth-layout");
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("bg-default");
    // var navbar = document.getElementsByClassName("navbar-horizontal")[0];
    // navbar.classList.add("navbar-light");
    // navbar.classList.add("navbar-transparent");
   this.getList();
  }
  is_login(){
    if(this.commonService.isloggedin){
      return false;
    }else{
      return true;
    }
  }
  islogin(acname, acid, sacid, modalForm: TemplateRef<any> ) {
    this.acname = acname;
    this.acid = acid;
    localStorage.removeItem('sub_asset_class_Id');
    localStorage.removeItem('asset_class_Id');
    console.log(acid, sacid, "campain");
    if(acid !='all'){
    localStorage.setItem('asset_class_Id', acid);
    localStorage.setItem('sub_asset_class_Id', sacid);
    }
    console.log(this.currentUser);
    //this.currentUser = this.authService.getCurrentUser();
    if (this.currentUser) {
      this.router.navigate(['/campaign', acname, acid]);

    } else {
      this.formModal = this.modalService.show(modalForm, this.form);
    }
  }
  getList() {

   

    this.apiService.callapi(this.apiService.APIS.MASTER_LIST, {}).subscribe(res => {

      this.tabro = res.data.filter(a => a.image_url != null && a.type == 'assets_class');
      console.log(this.tabro, "data");
       this.sub_assets = res.data.filter(a => a.type == 'sub_assets_class');
      // console.log('role list find ', this.tabrows);
      // console.log('role list find ', this.sub_assets_class);
      // this.loading = false;
    });
  }

  ngOnDestroy() {
    var html = document.getElementsByTagName("html")[0];
    // html.classList.remove("auth-layout");
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("bg-default");
    // var navbar = document.getElementsByClassName("navbar-horizontal")[0];
    // navbar.classList.remove("navbar-light");
    // navbar.classList.remove("navbar-transparent");
  }
  openFormModal(modalForm:TemplateRef<any>){
  //this.hide();
  this.formModal = this.modalService.show(modalForm, this.rform);
}

hide() {
  this.formModal.hide();
}
userRegister() {
  console.log(this.rmodel);

  this.apiService.callapi(this.apiService.APIS.USER_ADDEDIT, this.rmodel).subscribe(res => {
    console.log(res);
    this.hide();
    this.helperService.showNotification('info', 'Register Successfully, Login To Continue');
  }, err => {
    console.log(err);
    if (err.error.codeName == 'DuplicateKey') {
      this.helperService.showNotification('warning', 'User with Email Or Mobile Already Exist');
    }

  });
}

logout() {
  this.authService.logout();
  this.currentUser = this.commonService.getCurrentUser();
}

OnSubmit() {
  console.log(this.model);

  this.loadingIndicator = true;
  // if(this.signInForm.value.email !='' && this.signInForm.value.password !=''){
  this.apiService.callapi(this.apiService.APIS.AUTH_LOGIN, this.model).subscribe(data => {
    this.hide();
    if (data && data['token']) {
      this.commonService.isloggedin = true;
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('token', data['token']);

      localStorage.setItem('currentUser', JSON.stringify(data));
      this.authService.currentUserSubject.next(data);
      // this.authService.currentUserSubject.next(data['user']);
      this.helperService.showNotification('info', "Login Successfully");
      // if (data.user_type == 'super_admin') {
      //   this.router.navigate(['/admin/dashboard']);
      // } else {
        this.router.navigate(['/campaign', this.acname, this.acid]);
        //this.router.navigate(['/web/campaign', 'all', 'all']);
      // }

    }
  }, err => {
    this.loadingIndicator = false;
    this.commonService.notify('error', err.error);
    this.helperService.showNotification('warning', err.error);

    console.log(err);
  });
}




}
