
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';
import { Router } from '@angular/router';
import { HelperService } from './helper.service';
// import { User } from '../_models/user';


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    apiUrl: string = environment.apiUrl;

    public currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(private http: HttpClient,
        public commonService: CommonService,
        private router: Router, 
        public helperService: HelperService,
        ) {
            this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
            this.currentUser = this.currentUserSubject.asObservable();
         }


    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }
 
    login(model) {
        //        console.log(model);
        model.appversion = -1;
        let firebasetoken = localStorage.getItem('firebasetoken');
        if (firebasetoken) {
            model.firebasetoken = firebasetoken;
        }
        let appv = localStorage.getItem('appVersionNumber');
        if (appv) {
            model.appversion = appv;
        }
        return this.http.post<any>(this.apiUrl + '/auth/login', model).pipe(
            map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.commonService.setCurrentUser(user);
                    localStorage.setItem('token', user.token);
                    this.commonService.apiToken = user.token;

                    this.currentUserSubject.next(user);
                }
                return user;
            }));
    }


    signup(model) {
        //console.log(model);
        model.language = this.commonService.getAppLanguage();
        return this.http.post<any>(this.apiUrl + '/auth/signup', model).pipe(
            map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {

                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.commonService.setCurrentUser(user);
                    localStorage.setItem('token', user.token);
                    this.commonService.apiToken = user.token;
                    this.currentUserSubject.next(user);
                    //publish events
                    // this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
                    //    // this.setCurrentUser(user);
                    //    // return this.events.publish('user:login');
                    //   });
                }
                return user;
            }));
    }

    changePassword(model) {
        return this.http.post<any>(this.apiUrl + '/user/changepassword', model).pipe(
            map(user => {

                return user;
            }));
    }



    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        localStorage.clear();
        sessionStorage.clear();
        this.commonService.currentUser = null;
        this.commonService.isloggedin = false;
        this.currentUserSubject.next(null);
        this.router.navigate(['/']);
        this.helperService.showNotification('info', "Logout Successfully");
    }


    getCurrentUser() {
        //  const token = localStorage.getItem('token').trim();
        //   const decodedToken = jwt_decode(token);
        //console.log(decodedToken);
        // return decodedToken.data;
        return this.commonService.getCurrentUser();
    }



    checkPermission(pid) {
        return true;
        //    const cu= this.getCurrentUser();
        // //   console.log(pid,cu.role.permissions);
        //    if(cu.role.allowall_super === '1'){
        //        return true;
        //    }

        //    if(cu.role.permissions){
        //        const pus=cu.role.permissions.split(',');
        //        // console.log(pus,pid.toString(),pus.includes(pid.toString()));
        //        return pus.includes(pid.toString());
        //    }
        //       return true;
    }

    refreshToken() {
        let appversion: any = -1;

        /*
        let firebasetoken=localStorage.getItem('firebasetoken');
        let appv=localStorage.getItem('appVersionNumber');*/
        let currentDevice = this.commonService.getDevice();
        let appv = currentDevice.appVersionNumber;
        let firebasetoken = currentDevice.firebasetoken;
        if (appv) {
            appversion = appv;
        }
        let params: any = {};
        //?firebasetoken='+firebasetoken+'&appversion='+appversion
        params.firebasetoken = firebasetoken;
        params.appversion = appversion;

        if (localStorage.getItem('onesignal')) {
            let os: any = JSON.parse(localStorage.getItem('onesignal'));
            if (os) {
                params.os_userid = os.userId;
                params.firebasetoken = os.pushToken;
            }
        }


        return this.http
            .get<any>(this.apiUrl + '/auth/refreshtoken', { params: params }).pipe(
                map(user => {

                    localStorage.setItem('token', user.token);
                    this.commonService.apiToken = user.token;
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.commonService.setCurrentUser(user);
                    this.currentUserSubject.next(user);
                    //set language
                    this.commonService.setAppLanguage(user.language);

                    return user;
                }));
    }

}
