import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/providers/api.service";
import { AuthService } from "src/app/providers/auth.service";
import { HelperService } from "src/app/providers/helper.service";
import { NgForm } from "@angular/forms";
import { CommonService } from "src/app/providers";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
    selector: "app-deals",
    templateUrl: "./deals.component.html",
    styleUrls: ["./deals.component.scss"],
})
export class DealsComponent implements OnInit {
    formModal: BsModalRef;
    form = {
        keyboard: true,
        class: "modal-dialog-centered modal-md ap_modal mx-auto my-0",
    };
    rform = {
        keyboard: true,
        class: "modal-dialog-centered modal-md",
    };
    focus;
    focus1;
    model: any = {};
    loadingIndicator: boolean;
    loading: boolean;
    currentFilter: any;
    tabrows: any;
    assetsclass: string;
    subassetsclass: string;
    searchqclass: any = "all";
    searchsaqclass: any = "all";
    assetsclasses: any = [];
    sub_assets_class: any = [];
    assetsclassId: string;
    acname: any;
    acid: any;
    currentUser: any;
    id: any;
    rmodel: any = {};
    userToken: any;

    constructor(
        public commonService: CommonService,
        private modalService: BsModalService,
        public apiService: ApiService,
        public authService: AuthService,
        private route: ActivatedRoute,
        public helperService: HelperService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.subassetsclass = localStorage.getItem("sub_asset_class_Id");
        this.route.params.subscribe((params) => {
            this.assetsclass = params["assets_class"];
            this.assetsclassId = params["assets_class_Id"];
            if (this.assetsclass && this.assetsclass != "all") {
                this.searchqclass = this.assetsclassId;
                this.searchsaqclass = this.subassetsclass;
            }
            this.getAssetsClass();
            this.getList();
        });
    }

    onChange(e) {
        this.assetsclassId = this.searchqclass;
        this.getList();
    }

    getAssetsClass() {
        this.apiService
            .callapi(this.apiService.APIS.MASTER_LIST, {})
            .subscribe((res) => {
                console.log(res);
                this.assetsclasses = res.data.filter(
                    (a) => a.type == "assets_class"
                );
                this.sub_assets_class = res.data.filter(
                    (a) => a.type == "sub_assets_class"
                );
                // this.sub_assets_class = res.data.filter(
                //     (a) => a.image_url != null && a.type == "sub_assets_class"
                // );
            });
    }

    getSubAssetsClass() {
        if (this.searchqclass == "all") {
            return this.sub_assets_class;
        } else {
            return this.sub_assets_class.filter(
                (a) => a.asset_class == this.searchqclass
            );
        }
    }

    getList() {
        this.loading = true;
        this.apiService
            .callapi(this.apiService.APIS.CAMPAIGN_LIST, {
                asset_class: this.assetsclassId,
                sub_asset_class: this.searchsaqclass,
            })
            .subscribe((res) => {
                this.tabrows = res.rows;
                this.tabrows.forEach((i) => {
                    if (
                        (i.funds_raised / i.target_amount) * 100 > 80 &&
                        i.target_amount != i.funds_raised
                    ) {
                        return (i.hotDeals = true);
                    } else {
                        return (i.hotDeals = false);
                    }
                });
            });
    }

    islogin(id, modalForm: TemplateRef<any>) {
        this.id = id;
        this.userToken = localStorage.getItem("token");
        if (this.userToken == null || undefined) {
            this.formModal = this.modalService.show(modalForm, this.form);
        } else {
            this.router.navigate(["/campaign-profile", id]);
        }
    }

    openFormModal(modalForm: TemplateRef<any>) {
        //this.hide();
        this.formModal = this.modalService.show(modalForm, this.rform);
    }

    hide() {
        this.formModal.hide();
    }

    OnSubmit() {
        console.log(this.model);

        this.loadingIndicator = true;
        // if(this.signInForm.value.email !='' && this.signInForm.value.password !=''){
        this.apiService
            .callapi(this.apiService.APIS.AUTH_LOGIN, this.model)
            .subscribe(
                (data) => {
                    this.hide();
                    if (data && data["token"]) {
                        this.commonService.isloggedin = true;
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem("token", data["token"]);

                        localStorage.setItem(
                            "currentUser",
                            JSON.stringify(data)
                        );
                        this.authService.currentUserSubject.next(data);
                        // this.authService.currentUserSubject.next(data['user']);
                        this.helperService.showNotification(
                            "info",
                            "Login Successfully"
                        );
                        // if (data.user_type == 'super_admin') {
                        //   this.router.navigate(['/admin/dashboard']);
                        // } else {
                        this.router.navigate(["/campaign-profile", this.id]);
                        //this.router.navigate(['/web/campaign', 'all', 'all']);
                        // }
                    }
                },
                (err) => {
                    this.loadingIndicator = false;
                    this.commonService.notify("error", err.error);
                    this.helperService.showNotification("warning", err.error);

                    console.log(err);
                }
            );
    }

    userRegister(f: NgForm) {
        this.apiService
            .callapi(this.apiService.APIS.USER_ADDEDIT, this.rmodel)
            .subscribe(
                (res) => {
                    // localStorage.setItem('userId', res.data['userId']);
                    // localStorage.setItem('email', res.data['email']);
                    // localStorage.setItem('mobile', res.data['mobile']);
                    this.hide();
                    this.helperService.showNotification(
                        "info",
                        "Register Successfully, Login To Continue"
                    );
                    f.resetForm();
                },
                (err) => {
                    if (err.error.codeName == "DuplicateKey") {
                        this.helperService.showNotification(
                            "warning",
                            "User with Email Or Mobile Already Exist"
                        );
                    }
                }
            );
    }

    tcondition() {
        this.router.navigate(["/terms-conditions"]);
        this.hide();
    }
}
